import React, { Component } from 'react';
import {Breadcrumb, Button, Image} from 'react-bootstrap';
import 'moment/locale/id.js';
import CurrencyFormat from 'react-currency-format';
import ReactToPrint from "react-to-print";
const moment = require('moment');

class ComponentToPrint extends React.Component {

  constructor(props){
    super(props);

    this.state={
        student_id : '',
        is_show : false,
        image_64 : '',
        
    }
  }

   // onChangePotongan = e => {let potongan2 = this.state.tr_data_bayar[0].nominal * this.state.tr_data_bayar[0].potongan / 100
   //                          console.log(this.state.tr_data_bayar[0].potongan)

   //                         let nominal2 = this.state.tr_data_bayar[0].nominal - potongan2;
   //                         this.setState({nominal2)}

  componentDidMount() {
    this.setState({student_id: localStorage.getItem('DATA_JALUR').split('-')[0]});
    
    fetch(global.variable.LINK_API + 'student/detail/'+ localStorage.getItem('DATA_JALUR').split('-')[0],
    {
      method: 'post',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer '+ global.cookies.get('access_token'),
      },
      //body: JSON.stringify(data), 
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        this.setState(resData.data[0]);
        this.setState({is_show : true});
        console.log(this.state);

        let potongan2 = this.state.data_bayar[0].nominal * this.state.data_bayar[0].potongan / 100

                        let nominal2 = this.state.data_bayar[0].nominal - potongan2;
                           this.setState({hasil:nominal2})
        let total = this.state.data_bayar[2].nominal + this.state.data_bayar[3].nominal + this.state.data_bayar[4].nominal;
                    this.setState({kegiatan:total})
        let akhir = this.state.data_bayar[1].nominal + total;
                    this.setState({nominalakhir:akhir})

        
        let id = '';
        this.state.upload_documents[0].document_id===1? id = this.state.upload_documents[0].id : id = '';
        
        if(id!==''){
          fetch(global.variable.LINK_API + 'document/show/'+id,
          {
            method: 'get',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer '+ global.cookies.get('access_token'),
            },
            //body: JSON.stringify(this.state), 
          })
          .then(response =>  response.json())
          .then(resData => {
              console.log(JSON.stringify(resData));
            if(resData.message==="Success"){
              this.setState({image_64 : resData.data[0].link});
            }else{
              let msgerr = "";
              if(resData.errors){
                let count = Object.keys(resData.errors).length;
                for(let i=0; i<count; i++){
                  msgerr = msgerr + resData.errors[Object.keys(resData.errors)[i]] + "\n";
                }
              }
              global.swal(msgerr)
            }
            
          })
        }
      }
    })

    
  }

  renderTanggal(){
    if(this.state.registration_type_id==='JT' && this.state.is_reserve==='1'){
        return(<label>Sabtu, 7 Desember 2019 Pukul 12.00.</label>)
    }else{
      if(this.state.registration_type_id==='JT'){
        return(<label>Sabtu, 7 Desember 2019 Pukul 12.00.</label>)
      }else{
        return(moment().add(3, 'days').format('DD-MM-YYYY'))
      }
    }
  }

  render() {
    return (

      this.state.is_show?
        <div id="divPrint" style={{width:810, border: '0px solid black'}}>
            <div style={{alignItems: 'center', alignContent: 'center', justifyContent: 'center' ,textAlign: 'center',}}>
                <img src={require("../assets/images/kop_surat.png")} alt="kop" style={{width:700}}/>
            </div>
            <div style={{alignItems: 'center', alignContent: 'center', justifyContent: 'center', fontSize:11, marginTop:5, textAlign: 'center', }}>
              <label style={{textDecorationLine: 'underline'}}>SURAT KEPUTUSAN PENETAPAN FINANSIAL</label>
            </div>

            <div >
              <div style={{ margin:5, marginLeft:50, marginTop:5, fontSize:11}}>
                <label>Menimbang: </label>
              </div>
              <div style={{ margin:5, marginLeft:80, fontSize:11}}>
                <label>a.  Bahwa mutu dan peningkatan mutu pendidikan sangatlah bergantung pada besarnya dana pendidikan yang tersedia.</label>
              <br></br>
                <label>b.  Bahwa orang tua calon peserta didik yang lebih mampu haruslah membantu calon peserta didik yang tidak mampu 
                           tetapi berprestasi.</label>
              <br></br>
                <label>c. Bahwa selain seleksi akademis, seleksi mengenai administrasi keuangan terhadap para calon peserta didik sangatlah menentukan 
                          untuk penerimaan peserta didik baru.</label>
               <br></br>
                <label>d. Bahwa berdasarkan hasil penelitian dan evaluasi secara mendalam terhadap data-data pada berkas yang telah dimasukkan sebagai 
                          persyaratan pendaftaran calon peserta didik baru.</label>
              </div>
              <div style={{ margin:5, marginLeft:50, marginTop:10, fontSize:11}}>
                <label>Mengingat: </label>
              </div>
              <div style={{ margin:5, marginLeft:80, marginTop:10, fontSize:11}}>
                <label>1. Telah selesainya seleksi secara akademis dan diumumkannya nominasi para calon peserta
                          didik baru SMP PL Domenico Savio Semarang Tahun Pelajaran {this.state.tahun_ajaran_baru}.</label>
              <br></br>
                <label>2. Hasil musyawarah Panitia Penerimaan Peserta Didik Baru SMP PL Domenico Savio Semarang tahun pelajaran {this.state.tahun_ajaran_baru} yang terdiri dari unsur-unsur Tim Finansial PPDB tahun {this.state.tahun_ajaran_baru}, pihak sekolah.</label>
              <br></br>
              </div>

            </div> 

            <div style={{alignItems: 'center', alignContent: 'center', justifyContent: 'center', fontSize:11, marginTop:5, textAlign: 'center', }}>
              <label style={{textDecorationLine: 'underline'}}>MEMUTUSKAN</label>
            </div>

            <div className="rowHorizontal">
              <div style={{width:150, margin:5, marginLeft:50, fontSize:11}}>
                <label>Menetapkan</label>
              </div>
            </div>

            <div className="rowHorizontal">
              <div style={{width:130, margin:5, marginLeft:50, fontSize:11}}>
                <label>PERTAMA</label>
              </div>
              <div style={{margin:5, fontSize:11}}>
                <label>: Menyatakan bahwa calon peserta didik: </label>
                <br></br>

                 <label style={{width:150}}>a. Nama</label>
                <label>: {this.state.fullname}</label>
                <br></br>
                <label style={{width:150}}>b. No.Pendaftaran</label>
                <label>: {this.state.year + '-' + this.state.registration_type_id + '-' + this.state.registration_number}</label>
                <br></br>
                <label style={{width:150}}>c. Asal Sekolah</label>
                <label>: {this.state.school_from_name}</label>
                <br></br>
              </div>
            </div>


            <div className="rowHorizontal">
              <div style={{width:130, margin:5, marginLeft:50, fontSize:11}}>
                <label>KEDUA</label>
              </div>
              <div style={{margin:5, fontSize:11}}>
                <label>: Diterima sebagai peserta didik baru dengan membayar: </label>
              </div>
            </div>

            <div className="rowHorizontal" >
              <div style={{margin:5, marginLeft:150, fontSize:11}}>  
              <table border="1">
                <thead>
                      <tr >
                        <th width="120" style={{textAlign: "center", fontSize:11}}>Jenis </th>
                        <th width="100" style={{textAlign: "center", fontSize:11}}>Penetapan</th>
                        <th width="100" style={{textAlign: "center", fontSize:11}}>Potongan %</th>
                        <th width="100" style={{textAlign: "center", fontSize:11}}>Nominal Akhir</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{fontSize:11}}>UPP</td>
                        <td style={{fontSize:11}}><CurrencyFormat 
                              input="number"
                              prefix={'Rp. '}
                              value={this.state.data_bayar[0].nominal}
                              maxLength="2"  thousandSeparator={true}
                              style={{ width : '100%'}} displayType={'text'} /></td>
                        <td style={{fontSize:11, textAlign: "center"}}>{this.state.data_bayar[0].potongan}%</td>
                        <td style={{fontSize:11}}><CurrencyFormat 
                              input="number"
                              prefix={'Rp. '}
                              value={this.state.hasil}
                              maxLength="2"  thousandSeparator={true}
                              style={{ width : '100%'}} displayType={'text'} /></td>
                      </tr>
                      <tr>
                        <td style={{fontSize:11}}>SPP/bulan</td>
                        <td style={{fontSize:11}}><CurrencyFormat 
                              input="number"
                              prefix={'Rp. '}
                              value={this.state.data_bayar[1].nominal}
                              maxLength="2"  thousandSeparator={true}
                              style={{ width : '100%'}} displayType={'text'} /></td>
                        <td style={{fontSize:11, textAlign: "center"}}> </td>
                        <td rowspan="2" style={{fontSize:11}}><CurrencyFormat 
                              input="number"
                              prefix={'Rp. '}
                              value={this.state.nominalakhir}
                              maxLength="2"  thousandSeparator={true}
                              style={{ width : '100%'}} displayType={'text'} /></td>
                      </tr>
                      <tr>
                        <td style={{fontSize:11}}>Uang Kegiatan/bulan</td>
                        <td style={{fontSize:11}}><CurrencyFormat 
                              input="number"
                              prefix={'Rp. '}
                              value={this.state.kegiatan}
                              maxLength="2"  thousandSeparator={true}
                              style={{ width : '100%'}} displayType={'text'} /></td>
                        <td style={{fontSize:11, textAlign: "center"}}> </td>
                        
                      </tr>
                    </tbody>
                  </table>
                </div>
            </div>


            {/*<div className="rowHorizontal">
              <div style={{width:450, margin:5, marginLeft:50, fontSize:11}}>
                <label>KETIGA</label>
              </div>
              <div style={{margin:5, fontSize:11, marginLeft:-20}}>
                <label>: Pembayaran UPP ditransfer ke Bank Mandiri nomor rekening</label> <label style={{textDecorationLine: 'underline'}}> 136-00-0149000-9 </label> <label>atas nama</label>
                <label> SMP PANGUDI LUHUR DOMENICO SAVIO SMG PPDB dengan </label> <label  style={{textDecorationLine: 'underline'}}>mencantumkan nama dan nomor pendaftaran </label> 
                <label>pada kolom catatan / deskripsi serta mengunggah bukti pembayaran melalui akun pendaftar pada </label>
                <label>sistem PPDB Online SMP PL Domenico Savio.</label>
                              
              </div>
    </div>*/}

            <div className="rowHorizontal">
              <div style={{width:212, margin:5, marginLeft:50, fontSize:11}}>
                <label>KETIGA</label>
              </div>
              <div style={{margin:5, fontSize:11, marginLeft:-32}}>
                <label>: Keputusan ini berlaku sejak tanggal ditetapkan dan gugur bilamana 50% dari besarnya UPP tidak dibayarkan </label>
                <label> selambat-lambatnya tanggal </label> <label style={{textDecorationLine: 'underline'}}>
                {this.renderTanggal()}
                              {/* {this.state.registration_type_id==='JT' && this.state.is_reserve==='1'?
                              ('Kamis, 5 Desember 2019 Pukul 12.00.'):
                              (this.state.registrastion_type_id==='JT'?
                              'Senin,2 Desember 2019 Pukul 12.00.' :'Selasa, 12 November 2019.')} */}
                              </label>      
              </div>
            </div>

            <div className="rowHorizontal">
              <div style={{width:153, marginLeft:50, fontSize:11}}>
                <label>KEEMPAT</label>
              </div>
              <div style={{ fontSize:11, marginLeft:-15}}>
                <label>: UPP yang telah dibayarkan</label> <label style={{textDecorationLine: 'underline'}}>tidak dapat diminta kembali.</label>
              </div>
            </div>

            {/*<div className="rowHorizontal">
              <div style={{width:153, marginLeft:50, fontSize:11}}>
                <label>KEENAM</label>
              </div>
              <div style={{ fontSize:11, marginLeft:-15}}>
                <label>: Pembayaran SPP melalui virtual account yang akan diperoleh siswa pada awal tahun pelajaran 2021 / 2022.</label>
              </div>
                            </div>*/}


            <div className="rowHorizontal">
              <div style={{width:217, marginLeft:50, fontSize:11}}>
                <label>KELIMA</label>
              </div>
              <div style={{ fontSize:11, marginLeft:-15}}>
                <label>: Keputusan ini untuk diketahui oleh yang bersangkutan, dan dipergunakan sebagai pedoman bagi Panitia</label>
                <label> Penerimaan Peserta Didik Baru dan orang tua calon peserta didik.</label>
              </div>
            </div>

            <div className="rowHorizontal">
              <div style={{width:148, margin:5, marginLeft:50, fontSize:11}}>
                <label>KEENAM</label>
              </div>
              <div style={{margin:5, fontSize:11, marginLeft:-17}}>
                <label>: Keputusan ini akan dilakukan peninjauan kembali bilamana terdapat kekeliruan secara administratif.</label>
              </div>
            </div>



            <div className="rowHorizontal">
              <div style={{ margin:5, marginLeft:600, fontSize:11}}>
                <label>Ditetapkan di:</label>
                <br></br>
                <label>Semarang, {moment().format('DD-MM-YYYY')}</label>
              </div>
            </div>

            <div className="rowHorizontal">
              <div style={{width:150, margin:5, marginLeft:50, fontSize:11}}>
                <label>Menyetujui</label>
              </div>
            </div>

           <div className="rowHorizontal">
               <div style={{width:250, margin:5, marginLeft:50, fontSize:11}}>
                <label>Orang Tua calon Peserta Didik</label>
               </div>
                <div style={{margin:5, marginLeft:250, fontSize:11}}>
                <label>Kepala SMP PL Domenico Savio</label>
              </div>
            </div>


        <div className="rowHorizontal">
          <div style={{width:250, margin:50, marginLeft:50}}>
            
          </div>
        </div>


        <div className="rowHorizontal">
              <div style={{width:150, marginLeft:50, fontSize:11, marginTop:-10}}>
              <label style={{textDecorationLine: 'underline'}}>(_________________________________)</label>
              </div>
              <div style={{marginLeft:350, fontSize:11, marginTop:-10}}>
                <label style={{textDecorationLine: 'underline'}}>Antonius Parjana, FIC., S.Pd</label>
              </div>
        </div>

            <div >
              <div style={{ margin:5, marginLeft:50, marginTop:10, fontSize:11}}>
                <label style={{fontStyle:'italic'}}>*Agenda pemesanan seragam:</label> <label style={{textDecorationLine: 'underline', fontStyle:'italic'}}> 20 Januari 2025 – 1 Februari 2025.</label><br></br>
                <label style={{fontStyle:'italic'}}>*Syarat pemesanan seragam :</label> <label  style={{textDecorationLine: 'underline', fontStyle:'italic'}}> Pembayaran UPP ≥ 50%</label><br></br>
                {/*<label style={{fontStyle:'italic'}}>*Syarat mengikuti Masa Pengenalan Lingkungan Sekolah :</label> <label style={{textDecorationLine: 'underline', fontStyle:'italic'}}> lunas UPP</label><br></br>*/}
              </div>
            </div>
            
        </div>
        : ''
    );
  }
}

class Cetak extends Component {

  constructor(props){
    super(props);

    this.state={
        student_id : '',
    }
  }

  handleSubmit = ()=> {
    global.swal({
        text: 'Apakah SK sudah di PRINT dan Anda yakin akan disubmit?',
        closeOnEsc: false,
        closeOnClickOutside: false,
        buttons: [
          'Cancel',
          {text: 'Submit', 
          closeModal: false,}
        ],
      })
      .then((confirm) => {
        let errorMessage =  { code : 403, message : 'cancel' };
        if (!confirm) throw errorMessage;
        const data = { student_id :  localStorage.getItem('DATA_JALUR').split('-')[0], flow_id : localStorage.getItem('flow_id'), upp: localStorage.getItem('upp'), spp:localStorage.getItem('spp'), kegiatan: localStorage.getItem('kegiatan'), totalus : localStorage.getItem('totalus'), potongan: localStorage.getItem('potongan'), finalupp : localStorage.getItem('finalupp')}
      console.log(JSON.stringify(data))
      return fetch(global.variable.LINK_API + 'flow/update',
      {
        method: 'post',
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer '+ global.cookies.get('access_token'), 
        },
        body: JSON.stringify(data), 
      })
  
      .then(response =>  response.json())
      .then(resData => {
      console.log(resData);
      if(resData.message==="Success"){
        
        global.swal("Success", 'Berhasil Submit', "success")
        .then((value) => {
        window.location.href="/wawancara";
        });
      }
       
      })
  
     })
      .catch(err => {
        console.log(err)
        if (err) {
        if(err.code!==403){
          global.swal("Error", "Koneksi ke server gagal.", "info")
        }
        } else {
        global.swal.stopLoading();
        global.swal.close();
        }
      });
  }
  
  render() {
    return (
      <div className="content-wrapper">
          <section className="content-header">
              <div className="row">
                  <div className="col-md-12">
                      <div className="box">

                        <div className="divContainter">
                          <Breadcrumb>
                            <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                            <Breadcrumb.Item active>Cetak SK Finansial</Breadcrumb.Item>
                          </Breadcrumb>

                          <div>
                            <ReactToPrint
                              trigger={() =>  <Button style={{marginBottom:10}} block>Print</Button>}
                              content={() => this.componentRef}
                            />
                            <ComponentToPrint ref={el => (this.componentRef = el)} />
                          </div>
                          <div className='rowHorizontal'>
                
                           <Button onClick={this.handleSubmit}  block style={{margin:5, height:50}} >Submit</Button>
                         </div>
                        </div>
                      </div>
                  </div>
              </div>
          </section>
      </div>
      
    );
  }
}

export default Cetak;